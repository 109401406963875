<template>
    <div class="page">
        <NavBar></NavBar>
        <div class="content">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import NavBar from "./NavBar"
export default {
    name: "Page",
    components: { NavBar }
}
</script>

<style scoped>
.page{
    height: 100%;
    display: flex;
    box-sizing: border-box;
    padding-top: 15px;
}
.content{
    overflow: auto;
    flex: auto;
    box-sizing: border-box;
    margin: 0 15px 15px;
    border-radius: 4px;
    display: flex;
    height: calc(100% - 15px);
}
</style>